var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DefaultLayout',{staticClass:"order",attrs:{"breadcrumbs":[{ name: 'Заявки', value: 'orders' }],"isHideFiltersPanel":true,"heading":"Заявка на подменный автомобиль"}},[_c('ValidationObserver',{ref:"form",staticClass:"replacement-car-order-create order-form",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"order-form__container sp-xl"},[_c('RequestFormPartWrapper',{attrs:{"name":"Услуга","number":"01"}},[_c('Paragraph',[_vm._v("Включено в заказ на аренду")])],1),_c('RequestFormPartWrapper',{attrs:{"name":"Автомобиль","number":"02"}},[_c('CarFormPart',{attrs:{"id":_vm.form.carId,"isTO":true},on:{"update:id":function($event){return _vm.$set(_vm.form, "carId", $event)},"set-region":_vm.handleSetRegion}})],1),_c('RequestFormPartWrapper',{attrs:{"name":"Место и дата","number":"03"}},[_c('div',{staticClass:"replacement-car-order-create__city-and-date"},[_c('ValidationProvider',{staticClass:"replacement-car-order-create__city",attrs:{"tag":"div","rules":"required","name":"city"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VCombobox',{attrs:{"outlined":"","dense":"","clearable":"","color":"default","append-icon":"mdi-map-marker","label":"Город выдачи*","required":true,"error-messages":errors[0],"items":_vm.cities},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('Paragraph',{staticClass:"display-contents",attrs:{"tag":"span","size":"label","theme":"color-inherit"}},[_vm._v("Город выдачи"),_c('b',[_vm._v("*")])])]},proxy:true},{key:"no-data",fn:function(){return [_c('Paragraph',{staticClass:"order-form__autocomplete-no-data",attrs:{"size":"caption"}},[_vm._v("Совпадений нет")])]},proxy:true}],null,true),model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"replacement-car-order-create__city",attrs:{"tag":"div","rules":"required","name":"cityReturn"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VCombobox',{attrs:{"outlined":"","dense":"","clearable":"","color":"default","append-icon":"mdi-map-marker","label":"Город возврата*","required":true,"error-messages":errors[0],"items":_vm.cities},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('Paragraph',{staticClass:"display-contents",attrs:{"tag":"span","size":"label","theme":"color-inherit"}},[_vm._v("Город возврата"),_c('b',[_vm._v("*")])])]},proxy:true},{key:"no-data",fn:function(){return [_c('Paragraph',{staticClass:"order-form__autocomplete-no-data",attrs:{"size":"caption"}},[_vm._v("Совпадений нет")])]},proxy:true}],null,true),model:{value:(_vm.form.cityReturn),callback:function ($$v) {_vm.$set(_vm.form, "cityReturn", $$v)},expression:"form.cityReturn"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"tag":"div","rules":"required","name":"date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DatePicker',{staticClass:"replacement-car-order-create__date form__field--date",attrs:{"range":true,"label":"Период аренды*","error":errors[0],"required":true,"small":true,"isNotPrevDate":true},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('Paragraph',{staticClass:"display-contents",attrs:{"tag":"span","size":"label","theme":"color-inherit"}},[_vm._v("Период аренды"),_c('b',[_vm._v("*")])])],1)]}}],null,true)})],1)]),(_vm.isShowCarDriving)?_c('RequestFormPartWrapper',{attrs:{"name":"Перегон авто","isCloseble":true},on:{"close":_vm.handleCloseCarDriving}},[_c('CarDrivingFormPart',{attrs:{"data":_vm.form.carDriving,"formPartName":"Перегон авто","isAutoComplete":false},on:{"update:data":function($event){return _vm.$set(_vm.form, "carDriving", $event)}}})],1):_vm._e(),(_vm.isShowComment)?_c('RequestFormPartWrapper',{staticClass:"comments",attrs:{"name":"Комментарий","isCloseble":true},on:{"close":_vm.handleCloseComment}},[_c('TextInput',{staticClass:"full-width",attrs:{"label":"Комментарий","hide-details":"","small":true,"maxlength":"500","counter":"","rules":[_vm.rules.count]},model:{value:(_vm.form.comment),callback:function ($$v) {_vm.$set(_vm.form, "comment", $$v)},expression:"form.comment"}})],1):_vm._e()],1),_c('div',{staticClass:"order-form__container"},[_c('ControlsRequestFormPart',{on:{"submit":_vm.handleSubmit}},[(!_vm.isShowCarDriving)?_c('Link',{attrs:{"tag":"div","mod":"dark-blue","icon":"mdi-car-multiple"},on:{"click":function($event){_vm.isShowCarDriving = true}}},[_vm._v("Добавить перегон")]):_vm._e(),(!_vm.isShowComment)?_c('Link',{attrs:{"tag":"div","mod":"dark-blue","icon":"mdi-message-processing"},on:{"click":function($event){_vm.isShowComment = true}}},[_vm._v("Добавить комментарий")]):_vm._e()],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }